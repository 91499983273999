:root {
    --base-font-size: 16px;
    --base-font-weight: 400;
    --font-weight-bold: 700;
    --base-font-family: "Arial PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --base-line-height: 1.3;
}

/*
    Resets
*/

:focus {
    outline: none;
}

a,
a:hover {
    text-decoration: none;
}

strong {
    font-weight: var(--font-weight-bold);
}

img {
    display: block;
}

/*// --------------------------*/
/*// Layout (basic structure)*/
/*// --------------------------*/

html {
    display: flex;
    min-height: 100%;

    font-size: var(--base-font-size);
}

body {
    position: relative;

    display: flex;
    flex-direction: column;

    flex-grow: 1;

    width: 100%;
    min-width: 320px;
    overflow-x: hidden;

    font-size:var(--base-font-size);
    font-weight:var(--base-font-weight);
    font-family:var(--base-font-family);

    line-height: var(--base-line-height);
}

/*Header*/
header {
    position: relative;
    z-index: 3;

    flex-shrink: 0;
}

/*Content*/
.wrapper {
    position: relative;
    z-index: 1;
    flex-grow: 1;
}

.wrapper section {
    position: relative;
    z-index: 1;
}

/*Footer*/
footer {
    position: relative;
    z-index: 2;

    flex-shrink: 0;
}

.logo-link {
    display: block;
    width: 300px;
    margin: 50px auto;
}

.login-card {
    max-width: 650px;
    margin: 0 auto;
}

input[type=checkbox] {
    margin-right: 10px;
    display: initial;
    height: initial;
    width: initial;
}